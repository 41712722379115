<template>
  <div>
    <div align="center" class="my-5" v-if="dati_caricati === false">
      <b-spinner
      style="width: 3rem; height: 3rem;"
      class="mr-1"
      variant="primary"
      />
    </div>

    <section id="dashboard-ecommerce" class="mb-4" v-if="dati_caricati === true">
      <b-row class="pb-2">
        <b-col md="9">
          <h1 class="mb-0">{{ data.dati_fiscali.rag_soc }}</h1>
        </b-col>
        <b-col md="3">
          <small>attualmente</small><br />
          <strong>{{ data.articoli_carrello }} 
            <span v-if="data.articoli_carrello == 1">articolo</span>
            <span v-if="data.articoli_carrello != 1">articoli</span></strong> nel carrello          
        </b-col>
      </b-row>
      
      <b-row>
        <b-col md="6">
          <b-card
            no-body
            class="card-developer-meetup"
          >
            <div class="bg-light-primary rounded-top text-center">
              <b-img
                :src="require('@/assets/images/illustration/email.svg')"
                alt="anagrafica azienda"
                height="170"
              />
            </div>
            <b-card-body>
              <h3 class="mb-2">Informazioni Fiscali</h3>
              <!-- metting header -->
              <div class="meetup-header d-flex align-items-top">
                <div class="meetup-day">
                  <span class="text-muted">codice cliente:</span><br />
                  <h5 class="mb-0">
                    {{ data.dati_fiscali.codice }}
                  </h5>
                </div>
                <div class="my-auto">
                  <h3 class="mb-25">
                    {{ data.dati_fiscali.rag_soc }}
                  </h3>
                  <b-card-text class="mb-25">
                    p.iva: {{ data.dati_fiscali.piva }}
                  </b-card-text>
                  <b-card-text class="mb-25">
                    sdi: {{ data.dati_fiscali.sdi }}
                  </b-card-text>
                </div>
              </div>
              
              <b-media no-body>
                <b-media-aside class="mr-1">
                  <b-avatar
                    rounded
                    variant="light-primary"
                    size="34"
                  >
                    <feather-icon
                      icon="MapIcon"
                      size="18"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="mb-0">
                    {{ data.dati_fiscali.indirizzo }}<br />
                    {{ data.dati_fiscali.cap }} - {{ data.dati_fiscali.localita }} ({{ data.dati_fiscali.provincia }}) - {{ data.dati_fiscali.paese }}
                  </h6>
                </b-media-body>
              </b-media>
              <b-media no-body>
                <b-media-aside class="mr-1">
                  <b-avatar
                    rounded
                    variant="light-primary"
                    size="34"
                  >
                    <feather-icon
                      icon="PhoneIcon"
                      size="18"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="mb-0">
                    {{ data.dati_fiscali.telefono }}</h6>
                </b-media-body>
              </b-media>
              <b-media no-body>
                <b-media-aside class="mr-1">
                  <b-avatar
                    rounded
                    variant="light-primary"
                    size="34"
                  >
                    <feather-icon
                      icon="MailIcon"
                      size="18"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="mb-0">
                    {{ data.dati_fiscali.email }}</h6>
                </b-media-body>
              </b-media>

            </b-card-body>

          </b-card>
        </b-col>
        <b-col md="6">
          <b-card
            no-body
            class="card-developer-meetup"
          >
            <div class="bg-light-primary rounded-top text-center">
              <b-img
                :src="require('@/assets/images/illustration/sales.svg')"
                alt="spedizioni"
                height="170"
              />
            </div>
            <b-card-body>
              <b-card-title>Indirizzi di spedizione salvati</b-card-title>
              
              <div class="business-card">
                <div class="business-items">
                  <div
                    v-for="businessItem in data.spedizioni"
                    :key="businessItem.id"
                    class="business-item"
                  >
                    <div class="d-flex align-items-center justify-content-between">
                      <div>
                        <h5>{{ businessItem.rag_soc }}</h5>
                        {{ businessItem.indirizzo }}<br />
                        {{ businessItem.cap }} - {{ businessItem.localita }} ({{ businessItem.provincia }}) - {{ businessItem.paese }}
                      </div>
                      <b-badge variant="secondary">
                        {{ businessItem.codice_spe }}
                      </b-badge>
                    </div>
                  </div>
                </div>
              </div>

            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

      <b-card
        no-body
        v-if="data.leadcat.length !== 0"
        >
        <b-card-body>
            <b-tabs>
                <b-tab 
                  v-for="tabData in data.leadcat"
                  :key="tabData.id_tipo"
                  :title="`${tabData.nome_tipo} (${tabData.conta})`">
                    <div v-if="tabData.id_tipo != 1">
                      <b-table outlined :fields="fields" :items="tabData.leads" :tbody-tr-class="rowClass" responsive="sm">
                        <template #cell(operazioni)="data">
                          <div class="text-nowrap">
                            <feather-icon
                                :id="`${data.item.id_tipo}-riga-${data.item.id}-riepilogo`"
                                icon="FileTextIcon"
                                class="cursor-pointer mr-1"
                                size="21"
                                @click="$router.push({ name: 'crm-lead-dettaglio', params: { id_lead: data.item.id }})"
                            />
                            <b-tooltip
                                title="Dettaglio richiesta"
                                class="cursor-pointer"
                                :target="`${data.item.id_tipo}-riga-${data.item.id}-riepilogo`"
                            />
                          </div>
                        </template>

                      </b-table>
                    </div>

                    <div v-if="tabData.id_tipo == 1">
                      <b-table outlined :fields="fields_ordini" :items="tabData.leads" :tbody-tr-class="rowClass" responsive="sm">

                        <template #cell(titolo)="data">
                          <span class="font-weight-bolder">
                            {{ data.item.numero_ordine }}
                          </span><br />
                          <span class="font-small-2 text-muted">
                            del {{ data.item.data_inserimento }}
                          </span>
                        </template>

                        <template #cell(operazioni)="data">
                          <div class="text-nowrap">
                            <feather-icon
                                :id="`${data.item.id_tipo}-riga-${data.item.id}-ordine`"
                                icon="InfoIcon"
                                class="cursor-pointer mr-1"
                                size="21"
                                @click="$router.push({ name: 'crm-ordine-info', params: { id: data.item.id, numero_ordine: data.item.numero_ordine }})"
                            />
                            <b-tooltip
                                title="riepilogo ordine"
                                class="cursor-pointer"
                                :target="`${data.item.id_tipo}-riga-${data.item.id}-ordine`"
                            />
                            <feather-icon
                                :id="`${data.item.id_tipo}-riga-${data.item.id}-riepilogo`"
                                icon="FileTextIcon"
                                class="cursor-pointer mr-1"
                                size="21"
                                @click="$router.push({ name: 'crm-lead-dettaglio', params: { id_lead: data.item.id_lead, id_azienda: id_azienda }})"
                            />
                            <b-tooltip
                                title="Dettaglio richiesta"
                                class="cursor-pointer"
                                :target="`${data.item.id_tipo}-riga-${data.item.id}-riepilogo`"
                            />
                          </div>
                        </template>

                      </b-table>
                    </div>
                </b-tab>
            </b-tabs>

        </b-card-body>
       </b-card>

    </section>
  </div>
</template>

<script>
import router from '@/router'
import { BRow, BCol, BCard, BCardText, BCardTitle, BSpinner, BImg, BCardBody, BMedia, BMediaAside, BMediaBody, 
BAvatar, BAlert, BBadge, BButton, BTabs, BTab, BTable, BTooltip } from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BSpinner,
    BImg,
    BCardBody,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BAlert,
    BBadge,
    BButton,
    BTabs,
    BCardText,
    BTab,
    BTable,
    BTooltip,
  },
  data() {
    return {
      data: {
        dati_fiscali: { },
        spedizioni: { },
        articoli_carrello: '',
        leadcat: { }
      },
      userData: {},
      dati_caricati: false,
      id_azienda: '',

      fields: [
        {
          key: 'titolo',
          label: 'Oggetto',
          sortable: false
        },
        {
          key: 'stato_nome',
          label: 'Stato',
          sortable: false
        },
        {
          key: 'score',
          label: 'Score',
          sortable: false
        },
        {
          key: 'dataupd',
          label: 'Aggiornamento',
          sortable: false
        },
        {
          key: 'operazioni',
          label: 'Operazioni',
          sortable: false
        }
      ],
      fields_ordini: [
        {
          key: 'titolo',
          label: 'N.Ordine',
          sortable: false
        },
        {
          key: 'subtotale',
          label: 'Subtotale',
          sortable: false
        },
        {
          key: 'iva',
          label: 'Iva',
          sortable: false
        },
        {
          key: 'sconto',
          label: 'Sconto',
          sortable: false
        },
        {
          key: 'totalone',
          label: 'Totale ordine',
          sortable: false
        },
        {
          key: 'operazioni',
          label: 'Operazioni',
          sortable: false
        }
      ]
    }
  },
  created() {
    //console.log('id_azienda (speriamo bene) -> '+router.currentRoute.params.id_azienda);

    this.$http.get('v2/crmadmin/infoazienda/'+router.currentRoute.params.id_azienda)
      .then(response => {
        this.data = response.data

        this.id_azienda = router.currentRoute.params.id_azienda

        this.dati_caricati = true;
        //console.log(response.data.leadcat);
      })
  },
  methods: {
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.stato_nome == 'In attesa') return 'table-warning'
      if (item.stato_nome == 'In corso') return 'table-info'
      if (item.stato_nome == 'Concluso') return 'table-success'
      if (item.stato_nome == 'Nel cestino') return 'table-danger'
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
</style>